<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 20:32:59
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-31 16:52:12
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\culture\periodical\index.vue
 * @Description: 悦读人艺
 *
-->
<template>
  <div class="fixed-page-mobile culture-periodical-index-mobile">
    <div class="container">
      <SearchInput v-model="form.title" @search="handleSearch" />
      <div class="card" v-if="lastItem.id" @click="handleDetail(lastItem)">
        <div class="sub-title">
          <span>最新院刊</span>
        </div>
        <div class="detail-info">
          <div class="left">
            <c-image :src="lastItem.image" />
            <p>{{ lastItem.title }}</p>
          </div>
          <div class="right">
            <p class="desc">内容简介</p>
            <div class="content" v-html="lastItem.body"></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="sub-title">
          <span>往期院刊</span>
          <!-- <div  class="filter" @click="handleChangeSort">
            <span>{{ form.orderDirection ? "最新" : "最早" }}</span>
          </div> -->
        </div>
        <div
          v-for="dateItem in dateList"
          :key="dateItem.year"
          class="date-list"
        >
          <div class="date">{{ dateItem.year }}</div>
          <div
            v-if="dateItem.children && dateItem.children.length"
            class="periodical-list"
          >
            <PeriodicalItem
              v-for="item in dateItem.children"
              :key="item.id"
              :item="item"
            />
          </div>
        </div>
        <div v-if="hasNextPage" class="more-btn-box">
          <button class="more-btn" @click="handleMore">查看更多</button>
        </div>
      </div>
      <ShopList
        ref="shopListRef"
        :title="form.title"
        @show="dialogVisible = true"
      />
    </div>
    <PageFooter />
    <el-dialog
      :visible.sync="dialogVisible"
      width="80%"
      top="20vh"
      append-to-body
      custom-class="qr-dialog-box"
    >
      <div class="qr-dialog">
        <p>手机扫码进入</p>
        <p>北京人艺戏剧书店微信公众号，</p>
        <p>购买戏剧图书和戏剧文创。</p>
        <c-image :src="require('@/assets/img/performance/store.png')" />
        <p>扫描二维码进店</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getArticlePage,
  getArticleDetail,
  articleHit,
} from "@/service/culture";
import SearchInput from "@/components/pc/SearchInput.vue";
import PeriodicalItem from "./components/PeriodicalItem.vue";
import ShopList from "./components/ShopList.vue";

export default {
  components: { PeriodicalItem, ShopList, SearchInput },
  data() {
    return {
      lastItem: {},
      list: [],
      form: {
        title: "",
        orderDirection: 1,
        orderSign: 2,
        level1: "culture",
        level2: "book",
        status: 1,
        isShow: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 8,
      },
      hasNextPage: true,
      dialogVisible: false,
    };
  },
  created() {
    this.$store.dispatch("getDict", ["read-type"]);
  },
  computed: {
    readType() {
      return this.$store.state.dictMap["read-type"] || [];
    },
    yuankanId() {
      const obj = this.readType.find((item) => item.value === "院刊") || {};
      return obj.value || "";
    },
    dateList() {
      let yearArr = [];
      let yearObj = {};
      this.list.forEach((item) => {
        const year = dayjs(item.publishDate || item.createTime).format("YYYY");
        if (yearArr.indexOf(year) === -1) {
          yearArr.push(year);
        }
        if (!yearObj[year]) {
          yearObj[year] = [item];
        } else {
          yearObj[year].push(item);
        }
      });
      const formatList = yearArr.map((it) => ({
        year: it,
        children: yearObj[it],
      }));
      return formatList;
    },
  },
  watch: {
    yuankanId: {
      handler() {
        if (this.yuankanId) {
          this.handleSearch();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getData() {
      try {
        const res = await getArticlePage({
          ...this.pager,
          condition: { ...this.form, tag: this.yuankanId },
        });
        if (res.data) {
          const data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
            if (data[0] && !this.form.title && this.form.orderDirection) {
              this.lastItem = data[0];
            }
          } else {
            this.list = this.list.concat(data);
          }
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (!this.hasNextPage) return;
      this.pager.pageNo++;
      this.getData();
    },
    handleDetail(item) {
      articleHit(item.id);
      getArticleDetail(item.id).then((res) => {
        if (/iPad|iPhone|iPod|iOS/.test(navigator.userAgent)) {
          window.location.href = `${location.origin}${
            location.pathname || "/"
          }static/read.html?url=${encodeURIComponent(item.source)}&pageNum=${
            res.data.attatchmentUrl
          }`;
        } else {
          window.open(
            `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(item.source)}&pageNum=${
              res.data.attatchmentUrl
            }`
          );
        }
      });
    },
    handleChangeSort() {
      this.form.orderDirection = this.form.orderDirection ? 0 : 1;
      this.handleSearch();
    },
    handleChange() {
      this.handleSearch();
      this.$refs.shopListRef?.handleSearch();
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-periodical-index-mobile {
  p {
    padding: 0;
    margin: 0;
  }
  .font-bold {
    font-weight: bold;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .container {
    margin: 0 auto 50/75rem;
    box-sizing: border-box;
    width: 100%;
    padding: 30/75rem;
    background-color: #ffffff;
    .title {
      font-size: 26/75rem;
      font-weight: 500;
      color: #212122;
      padding-left: 26/75rem;
      position: relative;
      .img {
        position: absolute;
        left: 0/75rem;
        top: 50%;
        transform: translateY(-50%);
        width: 14/75rem;
        height: 26/75rem;
      }
    }
    .sub-title {
      margin: 60/75rem 0 30/75rem;
      font-size: 20/75rem;
      font-weight: 800;
      color: #212122;
      line-height: 30/75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filter {
        width: 54/75rem;
        height: 24/75rem;
        background: #f8f8fa;
        border-radius: 2/75rem;
        font-size: 13/75rem;
        font-weight: 400;
        color: #606266;
        line-height: 24/75rem;
        padding: 0 4/75rem;
        box-sizing: border-box;
        text-align: right;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 4/75rem;
          top: 4/75rem;
          width: 16/75rem;
          height: 16/75rem;
          .background-image("/performance/sort.png");
          background-size: 100%;
        }
      }
    }
    .detail-info {
      display: flex;
      .left {
        flex-shrink: 0;
        width: 280/75rem;
        height: 395/75rem;
        margin-right: 40/75rem;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          display: block;
        }
        p {
          color: #ffffff;
          font-size: 20/75rem;
          font-weight: 800;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 50/75rem;
          background: rgba(0, 0, 0, 0.3);
          text-align: center;
          line-height: 50/75rem;
        }
      }
      .right {
        flex: 1;
        font-size: 16/75rem;
        padding: 30/75rem 0;
        position: relative;
        .desc {
          color: #212122;
          font-weight: 500;
          margin-bottom: 20/75rem;
        }
        .content {
          color: #606266;
          line-height: 34/75rem;
          white-space: pre-wrap;
          height: 280/75rem;
          width: 100%;
          overflow-y: auto;
        }
        .detail-btn {
          position: absolute;
          bottom: 30/75rem;
          left: 0;
          background-color: var(--primary-color);
          color: #ffffff;
          width: 112/75rem;
          height: 34/75rem;
          font-size: 14/75rem;
          border-radius: 4/75rem;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
    .periodical-list {
      display: grid;
      gap: 30/75rem;
      grid-template-columns: repeat(2, 330/75rem);
    }
    .date-list {
      .date {
        height: 32/75rem;
        background: #f8f8fa;
        padding-left: 16/75rem;
        font-size: 16/75rem;
        font-weight: 800;
        color: #f9680d;
        line-height: 32/75rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 4/75rem;
          height: 32/75rem;
          background: #f9680d;
        }
      }
      .periodical-list {
        padding: 20/75rem 0 30/75rem;
      }
    }
    .more-btn-box {
      text-align: center;
      .more-btn {
        width: 422/75rem;
        height: 42/75rem;
        background: #ffffff;
        border-radius: 4/75rem;
        border: 1/75rem solid #d8dade;
        font-size: 14/75rem;
        color: #212122;
        cursor: pointer;
      }
    }
  }
}
.qr-dialog-box {
  .el-dialog__headerbtn {
    font-size: 24/75rem;
  }
}
.qr-dialog {
  text-align: center;
  font-size: 24/75rem;
  .img {
    width: 240/75rem;
    height: 240/75rem;
    margin: auto;
  }
  p {
    margin: 10/75rem 0;
  }
}
</style>
