<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 22:27:48
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-27 15:13:00
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\periodical\components\PeriodicalItem.vue
 * @Description: 期刊item
 *
-->
<template>
  <div :class="`periodical-item is-mobile ${type}`" @click="handleDetail">
    <c-image :src="item.image" class="poster" />
    <div class="content">
      <p class="overflow-ellipsis">{{ item.title }}</p>
      <p class="date-line">
        <span>{{
          item.publishDate || dayjs(item.createTime).format("YYYY-MM-DD")
        }}</span>
        <c-image :src="require('@/assets/img/performance/right.png')" />
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getArticleDetail, articleHit } from "@/service/culture";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "book",
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  methods: {
    handleDetail() {
      if (this.type === "shop") {
        this.$emit("toDetail");
      } else {
        articleHit(this.item.id);
        getArticleDetail(this.item.id).then((res) => {
          if (/iPad|iPhone|iPod|iOS/.test(navigator.userAgent)) {
            window.location.href = `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(
              this.item.source
            )}&pageNum=${res.data.attatchmentUrl}`;
          } else {
            window.open(
              `${location.origin}${
                location.pathname || "/"
              }static/read.html?url=${encodeURIComponent(
                this.item.source
              )}&pageNum=${res.data.attatchmentUrl}`
            );
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.periodical-item.is-mobile {
  box-sizing: border-box;
  width: 330/75rem;
  height: auto;
  cursor: pointer;
  .poster {
    width: 330/75rem;
    height: 465/75rem;
    display: block;
  }

  .content {
    box-sizing: border-box;
    padding: 20/75rem;
    width: 100%;
    height: auto;
    background: #f8f8fa;
    font-size: 16/75rem;
    font-weight: 800;
    color: #212122;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .date-line {
      margin-top: 10/75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12/75rem;
      font-weight: 400;
      color: #909399;
      .img {
        width: 58/75rem;
        height: 15/75rem;
      }
    }
  }
  &.shop {
    .poster {
      height: 375/75rem;
    }
  }
}
</style>
